import React from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';
import PlaneIconSVG from '../../assets/icons/plane-icon.inline.svg';

const ClaimFlightInformation = (props: any) => {
  const { formatMessage } = useIntl();
  const { flight } = props;

  return (
    <div>
      <h2>{formatMessage(messages.claimInfoHeader)}</h2>
      <div style={wrapper}>
        <div>
          <span>{flight?.airline?.name}</span>
        </div>
        <div>
          <div style={{ float: 'right', display: 'flex' }}>
            <span>
              <b>{flight?.departureAirport?.iata}</b>
            </span>
            <PlaneIconSVG className="mx-8 inline-block w-20 h-20 fill-primary" />
            <span>
              <b>{flight?.arrivalAirport?.iata}</b>
            </span>
          </div>
        </div>
        <div>
          <span style={{ fontSize: '14px' }}>
            {formatMessage(messages.flightNumberLabel)}: {flight?.airline_code}
            {flight?.flight_number}
          </span>
        </div>
        <div>
          <span style={{ float: 'right', fontSize: '14px' }}>
            {formatMessage(messages.flightDateLabel)}:{' '}
            {new Date(
              flight?.scheduled_gate_departure_date
            )?.toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClaimFlightInformation;

const wrapper = {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  border: '1px solid #D2D2D2',
  borderRadius: '8px',
  padding: '10px',
};
